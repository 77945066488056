import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./styles.css";

const CountDown = ({ duration, onComplete }) => {
    const renderTime = ({ remainingTime }) => {
        if (remainingTime === 0) {
          onComplete(); // Trigger the callback when time is up
          return <div className="timer">Time's up!</div>;
        }
    
        return (
          <div className="timer">
            <div className="text">Remaining</div>
            <div className="value">{remainingTime}</div>
            <div className="text">seconds</div>
          </div>
        );
      };
    
      return (
        <div className="timer-wrapper">
          <CountdownCircleTimer
            isPlaying
            duration={duration}
            size={120}
            strokeWidth={8}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[duration, duration * 0.6, duration * 0.3, 0]}
            onComplete={() => ({ shouldRepeat: false })}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      );
  };

  export default CountDown;