import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; import CountDown from './common/CountDown';
import AdsContainer from './common/GoogleAd';
import { Button } from 'react-bootstrap';

function Home() {
    const [isCountdownComplete, setIsCountdownComplete] = useState(false);
    const totalSteps = 2; 
    const navigate = useNavigate(); 
    const adsContainerRef = useRef(null);
  
    const handleComplete = () => {
        setIsCountdownComplete(true);
    };
  
    const handleNextStep = () => {
        if (totalSteps === 2) {
          navigate('/final-steps');
        }
    };

    return (
        <div className="text-center">
            <CountDown duration={20} onComplete={handleComplete} />
            <AdsContainer ref={adsContainerRef} />
  
            <div>
                1/{totalSteps}
            </div>

            <Button
                className="download-button btn-success"
                disabled={!isCountdownComplete}
                onClick={handleNextStep}
            >
                Next Step
            </Button>
        </div>
    );
}

export default Home;
