import React, { useState, useRef } from 'react';
import CountDown from './common/CountDown';
import AdsContainer from './common/GoogleAd';
import { Button } from 'react-bootstrap';

function Home2() {
    const [isCountdownComplete, setIsCountdownComplete] = useState(false);
    const adsContainerRef = useRef(null);
  
    const handleComplete = () => {
        setIsCountdownComplete(true);
    };

    const handleDownloadClick = () => {
        console.log('Download clicked!');
    };

    return (
        <div className="text-center">
            <CountDown duration={20} onComplete={handleComplete} />
            <AdsContainer ref={adsContainerRef} />
  
            <div>
                2/2
            </div>

            <Button
                className="download-button btn-success"
                disabled={!isCountdownComplete}
                onClick={handleDownloadClick}
            >
                Download Now
            </Button>
        </div>
    );
}

export default Home2;
