import React, { useRef, useEffect } from 'react';

const GoogleAd = ({ adSlot, adFormat = "auto", fullWidthResponsive = true, adRef, onAdLoaded }) => {
  useEffect(() => {
    const adElement = adRef.current;

    if (!adElement.getAttribute("data-ad-status")) {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        adElement.setAttribute("data-ad-status", "loaded");
      } catch (e) {
        console.error("AdSense error", e);
      }
    }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0 && onAdLoaded) {
          onAdLoaded();
          observer.disconnect();
        }
      });
    });

    if (adRef.current) {
      observer.observe(adRef.current, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, [adRef, onAdLoaded]);

  return (
    <div className="ad-container" ref={adRef}>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3757044111291964"
        data-ad-slot={adSlot}
        data-ad-format={adFormat}
        data-full-width-responsive={fullWidthResponsive ? "true" : "false"}
      ></ins>
    </div>
  );
};

const AdsContainer = React.forwardRef((_, ref) => {
  const adSlotIds = ["8078385350", "1720860561", "3217372116", "9187323016", "7139744175"];
  const adRefs = Array.from({ length: 10 }, () => React.createRef());
  const firstLoadedAdRef = useRef(null);
  const adLink = useRef(null); // Store the ad link here

  const handleAdLoad = (adIndex) => {
    if (!firstLoadedAdRef.current) {
      firstLoadedAdRef.current = adRefs[adIndex].current;

      const adIframe = adRefs[adIndex].current.querySelector("iframe");
      if (adIframe) {
        try {
          const adDoc = adIframe.contentDocument || adIframe.contentWindow.document;
          const foundAdLink = adDoc.querySelector("a");
          if (foundAdLink) {
            adLink.current = foundAdLink.href;
            console.log("Ad link found:", adLink.current);
          }
        } catch (error) {
          console.error("Access to iframe content is blocked due to cross-origin policy:", error);
        }
      }
    }
  };

  React.useImperativeHandle(ref, () => ({
    openAdLink() {
      if (adLink.current) {
        const tempLink = document.createElement("a");
        tempLink.href = adLink.current;
        tempLink.target = "_blank";
        tempLink.style.display = "none";
        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
      } else {
        console.warn("No ad link has been found.");
      }
    }
  }));

  return (
    <div className="ads-container">
      <p>Scroll down to download the video</p>
      {[...Array(5)].map((_, index) => {
        const slotId = adSlotIds[index]
        return (
          <GoogleAd
            key={index}
            adSlot={slotId}
            adRef={adRefs[index]}
            onAdLoaded={() => handleAdLoad(index)}
          />
        );
      })}
    </div>
  );
});

export default AdsContainer;
